<template>
    <v-data-table
        :headers="headers"
        :items="entities"
        class="elevation-1"
        :footer-props="{ 'itemsPerPageOptions': [50, 100, 200, -1] }"
    >
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>{{ $t('src\\views\\feedbacks\\list.7281') }}</v-toolbar-title>
                <v-spacer />
            </v-toolbar>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
            {{ item.createdAt | moment('DD.MM.YYYY HH:mm') }}
        </template>
        <template v-slot:[`item.edit`]="{ item }">
            <v-icon color="primary" @click="edit(item._id)">mdi-eye</v-icon>
        </template>
    </v-data-table>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { ACCESS } from '@/vars';

    export default {
        name: 'FeedbackList',
        components: {
        },
        data: () => ({
            ACCESS,
            headers: [],
        }),
        computed: {
            ...mapState('auth', ['user']),
            ...mapState('feedbacks', ['entities']),
        },
        async mounted() {
            await store.dispatch('feedbacks/fetch', {});
            this.headers = [
                { text: this.$t('src\\views\\feedbacks\\list.createdAt'), value: 'createdAt' },
                { text: this.$t('src\\views\\feedbacks\\list.name'), value: 'name' },
                { text: this.$t('src\\views\\feedbacks\\list.phone'), value: 'phone' },
                { text: this.$t('src\\views\\feedbacks\\list.email'), value: 'email' },
            ]
            if(this.user && this.user.adminAccess && this.user.adminAccess.feedbacks >= ACCESS.WRITE) {
                this.headers.push({ text: this.$t('src\\views\\feedbacks\\list.edit'), align: 'right', value: 'edit' })
            }
        },
        methods: {
            async edit(id) {
                await this.$router.push({ name: 'feedback-detail', params: { id } });
            }
        }
    }
</script>
